:root {
  --red-color: 232, 72, 85; //#E84855
  //shadow color
  --shadow-color: rgba(0, 0, 0, 0.12);
  --white-text-color: 255, 255, 255;
  --blue-color: 0, 127, 219; //007FDB
  --blue-secondary-color: #1b98f3;
  --green-color: 76, 175, 80; //#4CAF50
  --yellow-color: 255, 140, 0; //#FF8C00
  --violet-color: 152, 82, 241; //#9852F1
  --black-color: 0, 0, 0;
  --blue-primary-color: 0, 127, 219;
}

html {
  &.light {
    //bg color
    --wrapper-bg: #f0f2f5;
    --sidebar-bg: #1f2022;
    --main-element-bg: #ffffff;
    --main-element-txt-color: 32, 33, 35; // #202123;
    --section-border-bg: #e0e2e8;
    --row-bg-color: #edeff2;
    --table-even-row-bg: #f6f7f9;
    --option-hover-bg: rgba(0, 127, 219, 0.08);
    --input-state-bg: #f6f7f9;
    --visibility-shadow-bg: #ebf5fc;
    --calendar-element-bg: #ffffff;
    --board-state-bg: 32, 33, 35; // #202123 //0.3

    //text color
    --main-text-color: rgba(32, 33, 35, 0.6);
    --sidebar-elements-color: #ffffff;
    --table-row-element-color: rgba(188, 201, 214 0.8); //#BCC9D6

    --logo-image: url("../../assets/images/logo-light.png");
  }

  &.dark {
    --wrapper-bg: #161a1d;
    --sidebar-bg: #1e2226;
    --main-element-bg: #1d2125;
    --main-element-txt-color: 214, 224, 235; //#D6E0EB
    --section-border-bg: #3a424a;
    --row-bg-color: #282d33;
    --table-even-row-bg: #202429;
    --option-hover-bg: rgba(28, 43, 65, 1);
    --input-state-bg: #202429;
    --visibility-shadow-bg: #1c2b41;
    --calendar-element-bg: #21262a;
    --board-state-bg: 159, 173, 188; // #9FADBC;
    //text color
    --main-text-color: rgba(140, 155, 171, 1);
    --sidebar-elements-color: #d6e0eb;
    --table-row-element-color: rgba(188, 201, 214, 1); //#BCC9D6

    --logo-image: url("../../assets/images/logo-dark.png");
  }
}
