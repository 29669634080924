.games-control-table {
  margin-top: 1.6rem;
}
.table {
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: auto 10fr auto;
  width: 100%;
  border-radius: 1.6rem;
  background-color: var(--main-element-bg);
  box-shadow: 0 0 0 0.1rem var(--section-border-bg);
  //max-height: 100%;
  //overflow: hidden;
  //min-height: 40rem;
}
.table-title-wr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4.8rem;
  padding: 0 1.6rem;
  box-shadow: inset 0 -0.1rem 0 0 var(--section-border-bg);
  .actions {
    display: flex;
    align-items: center;
  }

  &.big {
    height: 7.2rem;
  }
}
.table-title {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--main-element-txt-color), 1);

  &.for-reports {
    display: block;
    flex: 1;
    min-width: 0;
  }
}
.table-title-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  font-size: 1.6rem;
  color: rgba(var(--blue-color), 1);
  cursor: pointer;
  margin-left: 0.8rem;
}
.table-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.table-content-header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 7.2rem;
  padding: 0 1.6rem;
  box-shadow: inset 0 -0.1rem 0 0 var(--section-border-bg);
  .table-left-actions,
  .table-right-actions {
    display: flex;
  }
  .table-left-actions > * {
    margin-right: 1rem;
  }
  .table-right-actions > * {
    margin-left: 1rem;
  }
}
.table-search {
  width: 28rem;
}
.table-create {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.table-create-icon {
  margin-right: 1rem;
  font-size: 1.4rem;
  color: rgba(var(--blue-color), 1);
}
.table-create-txt {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--blue-color), 1);
}
.table-holder {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
  overflow-x: auto;
}
.table-top {
  z-index: 10;
  flex: 0 0 auto;
  width: 100%;
}
.table-bottom {
  flex: 1;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;

  //padding-inline-end: 0.2rem;

  & > .table-info > .table-row:before {
    display: none;
  }
}
.table-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50.8rem;
  max-height: 100%;
  pointer-events: none;
}
.table-no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    max-width: 20rem;
  }
}
.table-no-data-txt {
  text-align: center;
  margin-top: 3.2rem;
}
.table-no-data-title {
  margin-bottom: 0.4rem;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.6rem;
  text-transform: capitalize;
  color: rgb(var(--main-element-txt-color));
}
.table-no-data-subtitle {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--main-text-color);
}

.table-nested {
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--section-border-bg);
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 240ms;
  padding-inline-start: 2.2rem;

  &:before {
    content: '';
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: var(--section-border-bg);
  }

  .table-col {
    padding: 0 1.6rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .header-row {
    .table-col {
      font-weight: 700;
      &:not(:last-child) {
        border-right: 0.1rem solid var(--section-border-bg);
      }
    }
  }
}
.table-nested-inner {
  overflow: hidden;
  .table-row {
    &:nth-child(even) {
      background-color: inherit;
    }
  }
}
.nested-table-info{
  .table-row {
    &:nth-child(even) {
      background-color: var(--row-bg-color);
    }
    //.table-row-cells{
    //  &:before{
    //    content: "";
    //    display: block;
    //    width: 4.4rem;
    //    height: 100%;
    //  }
    //}
  }
}
.table-row {
  position: relative;
  width: 100%;
  box-shadow: inset 0 -0.1rem 0 0 var(--section-border-bg);
  transition: background-color 240ms;
  &:not(.header-row) {
    .table-row-cells {
      &:hover {
        background-color: var(--visibility-shadow-bg);
      }
    }
  }
  .table-row-cells {
    display: flex;
    height: 4.4rem;
    align-items: center;
    position: relative;
    min-height: 4.4rem;
  }
  .icon-arrow-wrp {
    width: 4.4rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 1.2rem;
      color: rgba(var(--main-element-txt-color), 1);
      cursor: pointer;
      transition: transform 240ms;
    }
  }
  &:nth-child(even) {
    background-color: var(--table-even-row-bg);
  }

  .table-row-actions-panel {
    min-width: 12rem;
    opacity: 1;
  }

  &.header-row {
    background-color: var(--row-bg-color);

    .table-col {
      justify-content: flex-start;
      font-weight: 700;
      color: rgba(var(--main-element-txt-color), 1);
      &:last-child {
        .table-txt {
          text-transform: lowercase;
          &:first-letter {
            text-transform: uppercase;
          }
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background-color: var(--blue-secondary-color);
      z-index: 1;
    }
  }
  &.fix {
    position: sticky;
    top: 0;
    left: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 1px;
    height: 100%;
    background-color: var(--blue-secondary-color);
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4.4rem;
}

.loader-info {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--table-row-element-color);
}

.table-row-inner {
  width: 100%;
  position: relative;
  box-shadow: 0 -0.1rem 0 0 var(--section-border-bg) inset;

  &.open {
    & + .table-nested {
      grid-template-rows: 1fr;
    }
    .icon-arrow-wrp {
      i {
        transform: rotate(90deg);
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: var(--blue-secondary-color);
    z-index: 1;
  }
}
.table-col {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-width: 0;
  text-align: left;
  font-size: 1.4rem;
  line-height: 1.8rem;
  overflow: hidden;
  color: var(--table-row-element-color);
  padding: 0 1.6rem;
  // &:last-child {
  //   max-width: 12.8rem;
  // }

  & > div {
    &[data-type="Canceled"] {
      //ToDo: Remove !important when remove inline styles in Canceled state
      background-color: transparent !important;
      color: rgba(var(--red-color)) !important;
      border: none !important;
    }
  }
}
.paid-inner {
  min-width: 3.5rem;
  text-align: right;
  i {
    font-size: 2rem;
    color: rgba(var(--green-color));
  }
}
.table-row-actions-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  padding: 0 1.4rem;
  opacity: 0;
  transition:
    min-width 240ms,
    opacity 240ms;
  //pointer-events: none;
  &:hover {
    background-image: linear-gradient(
      270deg,
      var(--visibility-shadow-bg) 44.81%,
      rgba(235, 245, 252, 0) 91.48%
    );
  }
}
.table-row-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.6rem;
  color: rgba(var(--blue-color), 1);
  cursor: pointer;
  pointer-events: auto;
}
.table-header-col-action {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  &:hover {
    .table-header-col-action-arrow:not(.active) {
      color: var(--main-text-color);
    }
  }
}
.table-header-col-action-arrow {
  font-size: 1.6rem;
  color: rgba(var(--board-state-bg), 0.3);
  cursor: pointer;
  transition: color 240ms;

  &.active {
    color: rgb(var(--blue-color));
  }

  &:nth-child(2) {
    margin-left: -1rem;
  }
}
.table-txt {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table-info {
  width: 100%;
  max-height: 88rem;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-track {
    margin: 0.2rem 0;
  }
}
.nested-table-info {
  width: 100%;
  height: auto;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-track {
    margin: 0.2rem 0;
  }
}

.table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 6.8rem;
  padding: 0 1.6rem;
  .dropdown-toggle {
    //background-color: var(--form-elemnt-bg);
    border: 0.1rem solid var(--section-border-bg);
  }
}
.table-content-count {
  display: flex;
  align-items: center;
}
.table-content-count-info {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: rgba(var(--main-element-txt-color), 1);
}
.table-dropdown {
  width: 8rem;
  margin-right: 1.6rem;
}
.table-pagination {
  display: flex;
  align-items: center;
}
.table-pagination-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 0.8rem;
  background-color: var(--row-bg-color);
  color: rgba(var(--main-element-txt-color), 1);
  cursor: pointer;
  transition:
    background-color 240ms,
    color 240ms;

  &.disabled {
    //background-color: var(--table-header-row-bg);
    opacity: 0.5;
    color: rgba(var(--main-element-txt-color), 0.5);
    pointer-events: none;
  }
  &:not(.disabled) {
    &:hover {
      background-color: var(--section-border-bg);
    }
  }
}
.table-pagination-list {
  display: flex;
  margin: 0 0.6rem;
}
.table-pagination-list-item {
  min-width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: background-color 240ms;

  &:not(:last-child) {
    margin-right: 0.6rem;
  }

  &.active {
    background-color: rgba(var(--blue-color), 1);

    .table-pagination-list-item-txt {
      color: rgba(var(--white-text-color), 1);
    }
  }
  &:not(.active) {
    &:hover {
      background-color: var(--row-bg-color);
    }
  }
  &.active {
    cursor: default;
  }

  &.disabled {
    pointer-events: none;
  }
}
.table-pagination-list-item-txt {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgb(var(--main-element-txt-color));
  transition: color 240ms;
}
.table-printed-status {
  font-size: 1.2rem;

  &.printed {
    color: rgb(var(--green-color));
  }

  &.non-printed {
    color: rgb(var(--red-color));
  }
}
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.filters-section {
  display: flex;
  margin-bottom: 1.5rem;
  gap: 2.4rem;
}
//---Configuration style start ----
.config-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 1.6rem;
  border: 0.1rem solid var(--section-border-bg);
  background-color: var(--main-element-bg);
  min-height: 0;
  display: flex;
  flex-direction: column;
  .page-info-footer {
    height: 8rem;
  }
}
.config-tab-row {
  display: flex;
  align-items: center;
  box-shadow: inset 0 -0.1rem 0 0 var(--section-border-bg);
  .popup-tab-wrapper {
    margin-bottom: 0;
  }
}
.config-tab-row-item {
  padding: 1.2rem 2.4rem;
  cursor: pointer;
  border-bottom: 0.2rem solid transparent;
  transition: border-bottom 240ms;
  &:not(:first-child) {
    margin-left: 1.6rem;
  }
  &.active {
    border-bottom: 0.2rem solid rgba(var(--blue-color), 1);
    .config-tab-row-item-name {
      color: rgba(var(--blue-color), 1);
    }
  }
}
.config-tab-row-item-name {
  color: rgb(var(--main-element-txt-color));
  text-align: center;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
  transition: color 240ms;
}
.config-wrapper-inner {
  width: 100%;
  height: 100%;
  min-height: 0;
  padding: 1.6rem;
}
.config-ticket-container {
  width: 100%;
  display: flex;
  min-height: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  &.disable {
    opacity: 0.5;
    pointer-events: none;
  }
}
.config-wrapper-col {
  &:not(:first-child) {
    margin-left: 5.6rem;
  }

  &:first-child {
    min-width: 500px;
  }
}
.config-upload-image-row {
  display: flex;
  align-items: center;
}
.config-upload-image-label {
  span {
    color: rgba(var(--main-element-txt-color));
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
  }
}
.config-upload-image {
  width: 10.4rem;
  height: 10.4rem;
  margin-left: 1.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid var(--section-border-bg);
  transition: border 240ms;
  position: relative;
  &:hover {
    .delete-image-block {
      opacity: 1;
    }
  }
  &.upload-img {
    border: 0.1rem dashed var(--section-border-bg);
  }
  &.error-block {
    border: 0.1rem solid rgba(var(--red-color), 1);
  }
}
.config-upload-image-inner {
  width: 100%;
  height: 100%;
  background-color: initial;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  &.disable {
    pointer-events: none;
    opacity: 0.7;
  }

  & > img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
}
.plus-icon {
  font-size: 1.4rem;
  color: rgba(var(--main-element-txt-color), 1);
}
.label-text {
  color: var(--table-row-element-color);
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 500;
  margin-top: 1rem;
}
.upload-img-input {
  display: none;
}
.upload-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 0.9rem;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.delete-image-block {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.9rem;
  left: 0.9rem;
  right: 0.9rem;
  bottom: 0.9rem;
  z-index: 2;
  background-color: rgba(var(--black-color));
  opacity: 0;
  transition: opacity 240ms;
}
.action-btn {
  border: none;
  background-color: transparent;
  outline: none;
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;
  &:last-child {
    margin-left: 0.3rem;
  }
  span {
    color: var(--sidebar-elements-color);
    font-size: 1.9rem;
  }
}

.image-upload-block {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.image-error-block {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.icon-picture {
  color: rgb(var(--red-color));
  font-size: 3rem;
}
.image-name {
  color: rgb(var(--red-color));
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 500;
  display: inline-block;
  margin-top: 0.5rem;
}
.uploading-txt {
  color: var(--table-row-element-color);
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 1.6rem;
}
.loader-block {
  max-width: 8.8rem;
  width: 100%;
  height: 0.3rem;
  background-color: var(--wrapper-bg);
  border-radius: 1rem;
}
.loader-line {
  background-color: rgba(var(--blue-color), 1);
  height: 100%;
  border-radius: inherit;
}
.config-option {
  margin-top: 2.4rem;
}
.config-description-text {
  color: rgb(var(--main-element-txt-color));
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 400;
  margin-bottom: 2.4rem;
}
.config-option-wrapper {
  margin-top: 2.7rem;
}
.config-option-row {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}
.switch-block {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.switch-icon {
  display: inline-block;
  width: 4rem;
  height: 2.4rem;
  border-radius: 2.4rem;
  padding: 0.4rem;
  position: relative;
  background-color: grey;
  transition: 240ms ease-in-out;
  flex: 1 0 auto;
  &:before {
    content: "";
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    left: 0.4rem;
    bottom: 0.4rem;
    top: 0.4rem;
    border-radius: 50%;
    display: inline-block;
    background-color: var(--main-element-bg);
    transition: 240ms ease-in-out;
  }
}
.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
  &:checked {
    + {
      .switch-icon {
        background-color: rgba(var(--blue-color), 1);
        &:before {
          transform: translateX(1.6rem);
        }
      }
    }
  }
  &:disabled,
  &.disabled {
    + {
      .switch-icon {
        opacity: 0.5;
      }
    }
  }
}
.config-option-row-label {
  margin-left: 0.8rem;
}
.config-option-row-label-text {
  color: rgb(var(--main-element-txt-color));
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 500;
  display: inline-block;
  padding-left: 0.8rem;
}
.config-checkbox-block {
  margin-top: 1.6rem;
  margin-bottom: 2.4rem;
}
.config-checkbox-row {
  &:not(:last-child) {
    margin-bottom: 1.1rem;
  }
  .checkbox-label {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: rgb(var(--main-element-txt-color));
  }
}
.config-comment-block {
  margin-top: 2.4rem;

  &.disable {
    pointer-events: none;
    opacity: 0.7;
  }
}
.ticket-wrapper {
  &.open {
    background: rgba(32, 33, 35, 0.75);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    .ticket-inner {
      cursor: pointer;
      width: 39rem;
      transform: scale(0.8);
      padding: 2.7rem 2.1rem 3.8rem 2.1rem;
      transform: scale(0.9);
    }
    .ticket-logo-block {
      width: 31.2rem;
      height: 8.8rem;
      padding-bottom: 2.4rem;
      @media print {
        width: 312px;
        height: 88px;
      }
    }
    .ticket-info-row {
      span {
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }
    .ticket-team-info {
      margin-top: 1.6rem;
    }
    .ticket-bet-info-row {
      padding-bottom: 1.6rem;
    }
    .ticket-qr-img {
      width: 13.3rem;
      height: 13.3rem;
      @media print {
        width: 133px;
        height: 133px;
      }
    }
    .ticket-qr-description {
      margin-top: 1.6rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    .ticket-total-bet-info {
      margin: 1.6rem 0 2.4rem 0;
    }
    .ticket-comment-area {
      min-height: 10.3rem;
    }
    .ticket-comment-area-text {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    .ticket-code-block {
      margin-top: 2.5rem;
    }
    .ticket-code-img {
      width: 20.3rem;
      height: 7.4rem;
      @media print {
        width: 203px;
        height: 74px;
      }
    }
    .close-ticket {
      display: flex;
    }
  }
}
.close-ticket {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.4rem;
  height: 2.4rem;
  border: none;
  outline: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
  span {
    color: rgb(var(--white-text-color));
    font-size: 1.4rem;
  }
}
.ticket-inner {
  cursor: pointer;
  width: 37.5rem;
  border: 0.1rem solid var(--section-border-bg);
  padding: 2.6rem 2rem 3.7rem 2rem;
  background-color: rgb(var(--white-text-color));
  position: relative;
}
.ticket-preview-block {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(var(--black-color), 0.6);
}
.ticket-preview-block-inner {
  display: flex;
  align-items: center;
  text-align: center;
  color: rgb(var(--white-text-color));
  cursor: pointer;
}
.ticket-preview-text {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
  display: inline-block;
  padding-left: 1rem;
}
.visibility-icon {
  font-size: 2.4rem;
}
.ticket-logo-block {
  width: 30rem;
  height: 8.4rem;
  padding-bottom: 1.8rem;
  margin: 0 auto;
  @media print {
    width: 300px;
    height: 84px;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.ticket-bet-info-row {
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid #777;
}
.ticket-info-row {
  display: flex;
  justify-content: space-between;
  span {
    display: inline-block;
    font-size: 1.6rem;
    line-height: 1.8rem;
    &:not(:last-child) {
      padding-right: 0.5rem;
    }
  }
  &.total-bet-info-row {
    &:not(:last-child) {
      border-bottom: 0.1rem dashed rgba(119, 119, 119, 1);
      padding-bottom: 0.8rem;
    }
  }
}
.ticket-info-label {
  color: rgba(119, 119, 119, 1);
}
.ticket-info {
  color: rgba(var(--black-color), 0.9);
  &.bold {
    font-weight: 700;
  }
}
.ticket-info-row {
  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}
.ticket-team-info {
  margin-top: 1.1rem;
}
.ticket-team-info-title {
  margin-bottom: 0.8rem;
  span {
    color: rgb(var(--black-color));
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 600;
  }
}
.ticket-total-bet-info {
  border-radius: 0.2rem;
  border: 0.1rem solid rgb(var(--black-color));
  padding: 0.6rem;
  margin: 1.1rem 0 1.7rem 0;
}
.ticket-qr-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.7rem;
}
.ticket-qr-img {
  width: 9.5rem;
  height: 9.5rem;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.ticket-qr-description {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-top: 1.6rem;
}
.ticket-comment-area {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 0.2rem;
  border: 0.1rem solid rgb(var(--black-color));
  min-height: 9.9rem;
}
.ticket-comment-area-text {
  font-size: 1.6rem;
  line-height: 1.9rem;
  word-break: break-word;
}
.ticket-code-block {
  display: flex;
  justify-content: center;
  margin-top: 1.8rem;
  flex-direction: column;
  align-items: center;
}
.ticket-code-img {
  width: 19.5rem;
  height: 7.2rem;
  @media print {
    width: 195px;
    height: 72px;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.ticket-code-number {
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-top: 0.8rem;
}
.config-permissions-container {
  width: 100%;
}
.config-daily-count-description {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
  color: var(--table-row-element-color);
}
.config-daily-count-input {
  max-width: 22.5rem;
  width: 100%;
}
//---Configuration style start ----

.table-bank-bl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    color: var(--table-row-element-color);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8rem;
  }

  .amount {
    &.uper {
      color: rgba(var(--green-color), 1);
    }

    &.lower {
      color: rgba(var(--red-color), 1);
    }
  }

  //span {
  //  font-size: 1.2rem;
  //  color: var(--main-text-color);
  //  i {
  //    font-size: 1rem;
  //    margin-right: 0.4rem;
  //    &.icon-uper {
  //      color: rgba(var(--green-color), 1);
  //    }
  //    &.icon-lower {
  //      color: rgba(var(--red-color), 1);
  //    }
  //  }
  //}
}
.table-tab-row {
  width: 100%;
  display: flex;
}
.drop-box-blue {
  min-width: 20rem;
  position: relative;
  cursor: pointer;
  .dropdown-toggle {
    box-shadow: none;
    background: transparent;
  }
  .dropdown-txt {
    opacity: 0;
  }
  .dropdown-label {
    color: var(--blue-secondary-color);
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
  }
  .dropdown-toggle.lg {
    .dropdown-label {
      transform: none;
    }
  }
  .dropdown-icon {
    color: var(--blue-secondary-color);
  }
  &:before {
    content: "";
    display: block;
    height: 2.4rem;
    width: 0.1rem;
    background: var(--section-border-bg);
    position: absolute;
    top: 1rem;
    right: 0;
  }
}

.sync-button {
  position: relative;
  .sync-icon {
    display: block;
    font-size: 1.8rem;
    &.loading-icon {
      animation: loader-rotate 1800ms ease-in-out infinite;
    }
  }
  .computer-icon {
    font-size: 1rem;
    position: absolute;
    bottom: -0.3rem;
    right: -0.2rem;
  }
}

