.dashboard-ticket-report-wrp {
  .total-bet-amount-block-bottom-items {
    gap: 1.2rem;
    min-width: 0;
  }
}

.total-bet-amount-block-bottom-items {
  width: 100%;
  min-width: 0;
  height: 14.3rem;
  background-color: var(--main-element-bg);
  border: 0.1rem solid var(--section-border-bg);
  border-radius: 1.2rem;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.revenue-block-items-amount-block {
  width: 4rem;
  height: 4rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 2.2rem;
  }
}
.revenue-block-amount-wrp {
  min-width: 0;
}
.revenue-block-amount-number {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: rgba(var(--main-element-txt-color), 1);
  white-space: nowrap;
  @media screen and (max-width: 1600px) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
  @media screen and (max-width: 1440px) {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}
.revenue-block-amount-text {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--main-text-color);
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
