.modal-holder {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(var(--black-color), 0.6);
  z-index: 999;

  &.state-top {
    display: block;

    .popup-holder {
      margin: 0 auto;
      border-radius: 0 0 1.6rem 1.6rem;
    }
  }
}

.popup-holder {
  display: flex;
  flex-direction: column;
  max-height: 64rem;
  padding-bottom: 1.6rem;
  background-color: var(--main-element-bg);
  border: 0.1rem solid var(--section-border-bg);
  border-radius: 1.6rem;

  &.md {
    width: 50rem;
  }

  &.sm {
    width: 42rem;
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-right: 0.6rem;
  overflow: hidden;
}

//START POPUP HEADER

.popup-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  padding: 0 1.6rem;
}

.popup-header-title {
  flex: 1;
  min-width: 0;

  h1 {
    color: rgb(var(--main-element-txt-color));
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.popup-close-container {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;

  i {
    font-size: 1.2rem;
    color: var(--main-text-color);
  }
}

//END POPUP HEADER

//START Ticket popup

.ticket-head-row {
  width: 100%;
  padding: 1.6rem;
  border-top: 0.1rem solid var(--section-border-bg);
  border-bottom: 0.1rem solid var(--section-border-bg);
}
.ticket-status-row {
  margin-bottom: 1rem;
}
.ticket-status-row-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ticket-status-row-block {
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 0.8rem;
  background-color: rgba(var(--green-color), 0.08);
  padding: 0.8rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 3.3rem;
    color: rgba(var(--green-color), 1);
  }
}
.ticket-status-row-txt {
  color: rgba(var(--green-color), 1);
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
}

.ticket-head-states-container {
  display: flex;
  width: 100%;
  padding: 1.2rem 1.4rem;
  border-radius: 1.2rem;
  border: 0.1rem solid transparent;
  &.state-green {
    background-color: rgba(var(--green-color), 0.08);
    border: 0.1rem solid rgba(var(--green-color), 0.3);
    .states-text {
      color: rgba(var(--green-color), 1);
    }
  }
  &.state-red {
    background-color: rgba(var(--red-color), 0.08);
    border: 0.1rem solid rgba(var(--red-color), 0.3);
    .states-text {
      color: rgba(var(--red-color), 1);
    }
  }
  &.state-purple {
    background-color: rgba(var(--violet-color), 0.16);
    border: 0.1rem solid rgba(var(--violet-color), 0.4);
    .states-text {
      color: rgb(var(--violet-color));
    }
  }
  &.state-yellow {
    background-color: rgba(var(--yellow-color), 0.16);
    border: 0.1rem solid rgba(var(--yellow-color), 0.3);
    .states-text {
      color: rgba(var(--yellow-color));
    }
  }
}

.left-rows-cont {
  flex: 1;
  min-width: 0;
}

.left-cont-info {
  display: flex;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}

.gray-text {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 500;
  color: var(--main-text-color);
  margin-right: 0.8rem;
  text-transform: capitalize;
}

.left-black-text {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: rgb(var(--main-element-txt-color));
  font-weight: 600;

  > span {
    &:before {
      content: "  ";
    }
  }
}

.right-rows-cont {
  display: flex;
}

.states-text {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
  &.state-green {
    color: rgb(var(--green-color));
  }
  &.state-red {
    color: rgb(var(--red-color));
  }
  &.state-purple {
    color: rgb(var(--violet-color));
  }
  &.state-yellow {
    color: rgb(var(--yellow-color));
  }
}

.tickets-main-cont {
  width: 100%;
  height: 100%;
  padding: 0 1rem 0 1.6rem;
  &:not(:last-child) {
    .tickets-main-case {
      border-bottom: 0.1rem solid var(--section-border-bg);
    }
  }
}

.tickets-main-case {
  width: 100%;
  padding: 1.6rem 0 0 0;

  &:not(:last-child) {
    margin-bottom: 0.8rem;
    padding: 1.6rem 0;
  }
}

.cof-text-row {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 0.4rem;
  }
  margin-bottom: 0.4rem;
  &.under-block {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
.under-block-info {
  color: rgb(var(--main-element-txt-color));
  font-weight: 600;
}
.under-block-coef {
  color: rgb(var(--blue-color));
  font-weight: 700;
}
.cof-text-inner {
  font-size: 1.6rem;
  line-height: 2rem;
}
.cof-label {
  color: rgba(var(--main-element-txt-color), 0.6);
  font-weight: 500;
  display: inline-block;
  &.stake-label {
    text-transform: lowercase;
    &:first-letter {
      text-transform: capitalize;
    }
  }
}
.cof-number {
  color: rgb(var(--main-element-txt-color));
  font-weight: 600;
  display: inline-block;
  padding-left: 0.8rem;
}

.main-black-text {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
  color: rgb(var(--main-element-txt-color));

  > span {
    color: rgb(var(--blue-color));
    font-size: 1.6rem;
    line-height: 2.1rem;
    font-weight: 700;
    padding-left: 0.8rem;
  }
}
.under-block {
  display: flex;
  justify-content: space-between;
}

.main-gray-text {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
  color: var(--main-text-color);

  > span {
    padding-left: 0.8rem;
    color: rgb(var(--main-element-txt-color));
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
  }
}

.states-many-text {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;
}

//END Ticket popup

//START DISCARD CHANGE BLOCK

.small-popup-container {
  width: 100%;
  height: 100%;
  padding: 3.2rem 3.2rem 1.6rem 3.2rem;
}

.small-popup-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 0.8rem;
  margin-bottom: 1.6rem;

  &.state-yellow {
    background-color: rgba(var(--yellow-color), 0.08);

    .small-popup-icon {
      color: rgb(var(--yellow-color));
    }
  }

  &.state-blue {
    background-color: rgba(var(--blue-color), 0.08);

    .small-popup-icon {
      color: rgba(var(--blue-color), 1);
    }
  }

  &.state-red {
    background-color: rgba(var(--red-color), 0.8);

    .small-popup-icon {
      color: rgba(var(--red-color), 1);
    }
  }

  &.state-green {
    background-color: rgba(var(--green-color), 0.08);

    .small-popup-icon {
      color: rgb(var(--green-color));
    }
  }
}

.small-popup-icon {
  font-size: 3.3rem;
}

.attention-main-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 3.2rem;
}

.head-text {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
  padding: 0 0.8rem;
  color: rgb(var(--main-element-txt-color));
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.small-pp-main-text {
  margin-top: 0.8rem;
  padding: 0 0.8rem;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 500;
  color: var(--table-row-element-color);
}

.popup-button-case {
  display: flex;
}

.button-case {
  width: 17rem;
  height: 4.8rem;
  border-radius: 0.8rem;

  &:not(:last-child) {
    margin-right: 1.6rem;
  }
}

//END DISCARD CHANGE BLOCK

//START EDIT BALANCE CONTAINER
.edit-balance-holder {
  width: 100%;
}

.input-container {
  width: 100%;
  padding: 1.6rem;
  margin-bottom: 1.6rem;
  border-top: 0.1rem solid var(--section-border-bg);
  border-bottom: 0.1rem solid var(--section-border-bg);

  .input-holder {
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
}

.button-balance-case {
  width: 100%;
  padding: 0 1.6rem;
}

.button-balance-case {
  width: 100%;
  height: 4.8rem;
}

.popup-button-case-edit-balance {
  display: flex;
  width: 100%;
  padding: 0 4rem;
}

.button-inner-case {
  flex: 1;
  min-width: 0;

  &:not(:last-child) {
    margin-right: 1.6rem;
  }
}

.popup-tab-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1.6rem;
  position: relative;

  &.small {
    display: inline-flex;
    vertical-align: top;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.1rem;
    background-color: var(--section-border-bg);
  }
}

.popup-tab-el {
  width: 100%;
  min-width: 0;
  height: 4.8rem;
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
  color: rgb(var(--main-element-txt-color));
  text-transform: capitalize;
  cursor: pointer;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    pointer-events: none;
  }

  &:not(.active) {
    &:hover:after {
      background-color: rgba(var(--blue-color), 0.2);
    }
  }
  &.active:after {
    background-color: rgba(var(--blue-color), 1);
  }
}

.popup-balance-info {
  width: 100%;
  padding: 1.5rem 1.2rem;
  border: 0.1rem solid var(--section-border-bg);
  background-color: rgba(var(--blue-color), 0.04);
  border-radius: 0.8rem;
  margin-bottom: 1.6rem;
}

.popup-balance-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.popup-balance-title {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 500;
  color: var(--table-row-element-color);
  text-transform: capitalize;
}

.popup-balance-val {
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: right;
  color: rgb(var(--main-element-txt-color));
  font-weight: 700;
}

.convert-amount {
  display: flex;
}

.balance-popup-field {
  width: 100%;
  position: relative;
}

.balance-password-switcher {
  position: absolute;
  top: 50%;
  right: 1.2rem;
  transform: translateY(-50%);
  width: 2.4rem;
  height: 2.4rem;
  border: none;
  outline: none;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  font-family: "admin";
  z-index: 2;

  &.show:before {
    content: "\e924";
  }

  &.hide:before {
    content: "\e923";
  }
}

//END EDIT BALANCE CONTAINER
