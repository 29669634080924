.breadcrumbs {
  display: flex;
  align-items: center;
}
.breadcrumb {
  appearance: none;
  border: none;
  cursor: pointer;
  color: var(--table-row-element-color);

  &.active {
    color: rgba(var(--blue-color), 1);
  }
}
.breadcrumb-txt {
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.breadcrumb-arrow {
  margin: 0 1.4rem;
  font-size: 0.8rem;
  color: var(--main-text-color);
}
