
//Game Config Style
.game-control {
  display: grid;
  grid-template-rows: auto 11fr;
  width: 100%;
  height: 100%;
  min-height: 0;
  .settings-inputs {
    margin-bottom: 2.4rem;
    margin-top: 2.4rem;
  }
}
.game-control-inner{
  width: 100%;
}
.transfer-wrapper {
  width: 100%;
  display: flex;
  gap: 0.6rem;
  min-height: 0;
}
.transfer-list {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  border: 0.1rem solid var(--section-border-bg);
  border-radius: 0.8rem;
  min-height: 0;
}

// TODO need ui change
.transfer-list-empty-state {
  color: rgba(var(--main-element-txt-color), 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 100%;
}

.transfer-list-header {
  flex: 0 0 auto;
  padding: 1.2rem 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 500;
  border-bottom: 0.1rem solid var(--section-border-bg);
}
.transfer-list-header-title {
  color: rgba(var(--blue-color),1);
}
.transfer-list-header-info {
  color: var(--main-text-color);

}
.transfer-list-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  padding: 1.2rem 1.4rem;
}
.transfer-list-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 0;
}
.transfer-item-search {
  flex: 0 0 auto;
  padding: 1.2rem 1.6rem;
  border-bottom: 0.1rem solid var(--section-border-bg);
  .search-panel {
    border-radius: 0.8rem;
  }
}
.transfer-list-content-inner {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  min-height: 0;
  padding: 0 0.2rem;
}
.transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 4.8rem;
  border-radius: 0.6rem;
  padding: 1.2rem;
  &:not(.select-all) {
    border: 0.1rem solid var(--section-border-bg);
  }
  &:not(:last-child) {
    margin-bottom: 0.6rem;
  }
  .indicator-icon {
    cursor: pointer;
    font-size: 1.6rem;
    color: var(--main-text-color);
  }
  .checkbox-holder {
    margin-bottom: 0;
  }
}
.transfer-operation {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.transfer-btn {
  border: none;
  outline:none;
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
  i {
    color: rgba(var(--main-element-txt-color), 0.6);
  }

  &:first-child {
    margin-bottom: 2.4rem;
  }
}
