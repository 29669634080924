.heading-line-block {
  display: flex;
  align-items: center;
  width: 100%;
}
.heading-line-txt {
  flex: 0 0 auto;
  margin-right: 1.6rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: rgba(var(--main-element-txt-color), 1);
  text-transform: capitalize;
}
.heading-line {
  flex: 1;
  height: 0.1rem;
  background-color: var(--section-border-bg);
}
