.calendar-holder {
  position: absolute;
  left: 0;
  border-radius: 0.8rem;
  animation: fadeIn 240ms;
  z-index: 100;

  &.to-top {
    bottom: 100%;
    margin-bottom: 0.4rem;
  }

  &.to-bottom {
    top: 100%;
    margin-top: 0.4rem;
  }
}
.datepicker {
  display: inline-grid;
  grid-template-columns: 12fr;
  grid-template-rows: auto 11fr;
  padding: 1.6rem;
  border: 0.1rem solid var(--section-border-bg);
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 0.4rem var(--shadow-color);
  background-color: var(--main-element-bg);
}
.date-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.date-actions {
  display: flex;
}
.date-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 240ms;
  background-color: var(--main-element-bg);
  &:hover {
    background-color: var(--option-hover-bg);
  }

  &:not(:last-child) {
    margin-right: 0.4rem;
  }

  i {
    font-size: 1.2rem;
    color: rgb(var(--main-element-txt-color));
  }
}
.date-month-year {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--main-element-txt-color), 1);
}
.calendar-days {
  width: 100%;
}
.calendar-days-row {
  display: flex;

  &.days-heading {
    .calendar-day {
      font-weight: bold;
    }
  }
}
.calendar-days-content {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;

  .calendar-days-col {
    cursor: pointer;
    transition: background-color 240ms;

    &:hover {
      background-color: var(--row-bg-color);
    }

    &.selected {
      background-color: rgba(var(--blue-color), 1);

      .calendar-day {
        color: rgba(var(--white-text-color), 1);
      }
    }

    &.disabled {
      pointer-events: none;

      .calendar-day {
        color: rgba(var(--main-element-txt-color), 0.5);
      }
    }
  }
}
.calendar-days-col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
}
.calendar-day {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgb(var(--main-element-txt-color));
  transition: color 240ms;
}
//date picker hours
.date-picker-hours {
  display: flex;
  margin-top: 1.6rem;
}
.date-picker-hours-col {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  &:not(:first-child) {
    margin-left: 1.8rem;
  }
  &:last-child {
    &:after {
      content: ":";
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      left: -1.3rem;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.4rem;
      color: rgb(var(--main-element-txt-color));
    }
  }
  input {
    width: 100%;
    height: 100%;
    min-height: 4rem;
    background-color: var(--main-element-bg);
    box-shadow: inset 0 0 0 0.1rem var(--section-border-bg);
    border-radius: 0.8rem;
    outline-style: none;
    color: var(--main-text-color);
    transition: border-color 240ms;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-align: center;
    padding: 0;
    border: none;
  }
}
.date-picker-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  //height: 100%;
  min-height: 2.4rem;
  margin: 0.4rem 0;
  border: none;
  outline: none;
  background-color: var(--row-bg-color);
  cursor: pointer;
  border-radius: 0.8rem;
  color: rgb(var(--main-element-txt-color));
  &:hover {
    background-color: var(--section-border-bg);
  }
  &.decrement-btn {
    i {
      transform: rotate(90deg);
    }
  }
  &.increment-btn {
    i {
      transform: rotate(-90deg);
    }
  }
  i {
    font-size: 1.2rem;
    display: block;
  }
}
