.betshops {
  width: 100%;
  max-height: 100%;
}
.betshops-settings {
  display: flex;
  align-items: center;
  width: 100%;
  height: 8.8rem;
  border-radius: 1.6rem;
  box-shadow: 0 0 0 0.1rem var(--section-border-bg);
  background-color: #ffffff;
  padding: 0 2rem;
  margin-bottom: 1.6rem;
}
.settings-input-group {
  flex: 1;
  min-width: 0;
  display: flex;
}
.settings-input {
  width: 100%;
  &:not(:last-child) {
    margin-right: 1.2rem;
  }
}
.settings-actions {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}
.settings-action {
  min-width: 10rem;
  &:not(:last-child) {
    margin-right: 1.2rem;
  }
}
