.checkbox-holder {
  //min-width: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  //margin-right: 10px;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.transaction-checkbox-label-name {
  flex: 1 1;
}

.checkbox {
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  flex: 0 0 auto;

  &:hover {
    .checkbox-checkmark {
      border-color: rgb(var(--blue-color));
    }

    input[type="checkbox"]:checked ~ .checkbox-checkmark,
    .checkbox-checkmark.minus {
      background-color: var(--blue-secondary-color);
    }
  }

  input[type="checkbox"] {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }

  input[type="checkbox"]:checked ~ .checkbox-checkmark,
  .checkbox-checkmark.minus {
    background-color: rgb(var(--blue-color));
    border-color: transparent;
  }

  input[type="checkbox"]:checked ~ .checkbox-checkmark:after,
  .checkbox-checkmark.minus:after {
    opacity: 1;
  }
}

.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0.2rem;
  border-style: solid;
  border-color: var(--section-border-bg);
  border-radius: 0.3rem;
  pointer-events: none;
  transition:
    border-color 240ms,
    background-color 240ms;

  &.check {
    &:after {
      top: 0.1rem;
      left: 0.5rem;
      border-width: 0 0.2rem 0.2rem 0;
      transform: rotate(45deg);
      width: 0.5rem;
      height: 1rem;
      border-style: solid;
      border-color: #ffffff;
    }
  }
  &.minus {
    &:after {
      top: 0.1rem;
      left: 0.2rem;
      bottom: 0;
      width: 1rem;
      height: 0.2rem;
      margin: auto;
      border-radius: 0.3rem;
      background-color: #ffffff;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;
    transition: opacity 240ms;
  }
}

.checkbox-label {
  display: block;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 500;
  transition: color 240ms;
  color: rgb(var(--main-element-txt-color));
}
