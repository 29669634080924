.popover {
  position: absolute;
  z-index: 100;
  width: 100%;
  padding: 0.6rem;
  border: 0.1rem solid var(--section-border-bg);
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 0.4rem var(--shadow-color);
  background-color: var(--main-element-bg);
  color: rgba(var(--main-element-txt-color), 1);
  animation: fadeIn 240ms;

  &.to-top {
    bottom: 100%;
    margin-bottom: 0.4rem;
  }

  &.to-bottom {
    top: 100%;
    margin-top: 0.4rem;
  }

  &.absolute {
    top: 0;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}
.popover-content {
  width: 100%;
  max-height: 25.4rem;
  overflow-y: auto;
  overflow-x: hidden;
}
