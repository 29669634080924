.betting-wrp {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.betting-row {
  display: flex;
  align-items: center;
  gap: 2rem;
  min-width: 0;
}
.maximum-input {
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 1.2rem;
    height: 0.1rem;
    background-color: var(--section-border-bg);
    position: absolute;
    top: 50%;
    left: -1.6rem;
    transform: translateY(-50%);
  }
}
.amount-label {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
  color: rgba(var(--main-element-txt-color), 1);
}
