.page-info {
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: auto 10fr auto;
  width: 100%;
  border-radius: 1.6rem;
  background-color: var(--main-element-bg);
  box-shadow: 0 0 0 0.1rem var(--section-border-bg);
  overflow: hidden;
  min-height: 0;
  // todo
  height: 100%;
  //height: 100vh;
}
.page-info-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4.8rem;
  padding: 0 1.6rem;
  box-shadow: inset 0 -0.1rem 0 0 var(--section-border-bg);
}
.page-info-header-title {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--main-element-txt-color), 1);
}
.page-info-header-action {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color: rgba(var(--blue-color), 1);
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.page-info-header-action-txt {
  padding: 0 0.7rem;
}
.page-info-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // todo watch later for this part, need to change the code and component
  > .popup-tab-wrapper {
    .popup-tab-el {
      flex: 0 0 auto;
      width: auto;
    }
  }
}
.user-reports-wrapper {
  padding: 1.6rem 1.6rem 0;
}
.page-info-content-header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 7.2rem;
  padding: 0 1.6rem;
  box-shadow: inset 0 -0.1rem 0 0 var(--section-border-bg);
}
.page-info-content-header-left {
  display: flex;
  align-items: center;
}
.page-info-player-name {
  font-size: 2.4rem;
  line-height: 3.2rem;
  text-transform: capitalize;
  color: rgb(var(--main-element-txt-color));
}
.name-data-blcok {
  display: flex;
  flex-direction: column;
  padding-left: 1.6rem;
}
.page-info-player-data {
  display: flex;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: var(--main-text-color);

  span {
    &:not(:last-child) {
      padding-right: 0.5rem;
    }
  }
}
.page-info-data-line {
  width: 0.1rem;
  height: 2.4rem;
  background: var(--section-border-bg);
  margin-right: 2.4rem;
}
.page-info-content-header-right {
  display: flex;
  //
  //div {
  //  display: flex;
  //  justify-content: flex-end;
  //  align-items: center;
  //}
}
.settings-btn-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0.1rem;
  button {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    i {
      font-size: 2rem;
    }
  }
}
.lock-cashier-text {
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: capitalize;
  padding-left: 0.8rem;
}
.settings-main-btn {
  min-width: 13.3rem;
}

.page-info-search {
  width: 28rem;
}
.page-info-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 6.8rem;
  padding: 0 1.6rem;
  box-shadow: inset 0 0.1rem 0 0 var(--section-border-bg);
}
.page-info-footer-actions {
  display: flex;
  align-items: center;
}
.page-info-footer-action {
  &:not(:last-child) {
    margin-right: 1.2rem;
  }
}
.page-info-holder {
  width: 100%;
  height: 100%;
  min-height: 20rem;
  overflow-y: auto;
  padding: 1.6rem;
}
.settings-headline {
  width: 100%;
  margin-bottom: 1rem;
}
.settings-inputs {
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  grid-gap: 2.4rem;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 3.2rem;
}

.page-info .add-edit-view-loader {
  height: 300px;
}
