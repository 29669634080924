.sign-in-btn-wrp{
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  appearance: none;
  cursor: pointer;
  transition: background-color 240ms, box-shadow 240ms, color 240ms, transform 240ms;
  &.user-settings-btn {
    .icon-wr {
      &:first-child {
        i {
          color: rgb(var(--blue-color));
          font-size: 1.8rem;
        }
    }
    }
  }
  &.justif-end {
    display: flex;
    justify-content: flex-end;
  }

  &.primary {
    background-color: rgba(var(--blue-color),1);
    color: rgb(var(--white-text-color));

    &:hover {
      background-color: var(--blue-secondary-color);
    }

    &:active {
      background-color: rgba(var(--blue-color),1);
      box-shadow: 0 0 0 0.3rem rgba(var(--blue-color),0.2);
    }
  }

  &.none {
    background-color: transparent;
    color: rgb(var(--white-text-color));

  }

  &.secondary {
    background-color:var(--row-bg-color);
    color:  rgba(var(--main-element-txt-color),1);

    &:hover {
      background-color: var(--section-border-bg);
    }

    &:active {
      background-color: var(--option-hover-bg);
    }
  }

  &.secondary-outline {
    color: rgb(var(--main-element-txt-color));
    box-shadow: 0 0 0 0.1rem var(--section-border-bg);

    &:hover {
      background-color: var(--option-hover-bg);
    }

    &:active {
      background-color: rgba(var(--blue-color), 0.08);
    }
  }

  &.light {
    color: rgb(var(--main-element-txt-color));

    &:hover {
      background-color: var(--section-border-bg);
      box-shadow: 0 0 0 0.1rem var(--section-border-bg);
    }

    &:active {
      background-color: var(--option-hover-bg);
      box-shadow: 0 0 0 0.1rem transparent;
    }
  }

  &.light-secondary {
    color: rgba(var(--blue-color),1);

    &:hover {
      color: rgb(var(--main-element-txt-color));
    }

    &:active {
      color: rgba(var(--main-element-txt-color),0.8);
    }
  }

  &.danger {
    color: rgba(var(--red-color),1);

    &:hover {
      color: rgba(var(--red-color),0.7);
    }

    &:active {
      color: rgba(var(--red-color),0.7);
    }
  }

  &.success {
    color: rgb(var(--green-color));

    &:hover {
      color: rgba(var(--green-color), 0.7);
    }

    &:active {
      color: rgba(var(--green-color), 0.7);
    }
  }

  &.sm {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
  }

  &.md {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 0.8rem;
  }

  &.default {
    width: 100%;
    height: 4.8rem;
    padding: 0 0.6rem;
    border-radius: 0.8rem;
  }

  &.defaultSmall {
    flex: 0 0 auto;
    height: 4rem;
    padding: 0 0.6rem;
    border-radius: 0.8rem;
  }

  i {
    font-size: 1.2rem;
  }
}
.button-txt {
  padding: 0 1rem;
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
}
