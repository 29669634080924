//dropdown style
.dropdown {
  position: relative;
  width: 100%;
  border-radius: 0.8rem;

  &.dropdown-in-reports {
    width: auto;
    flex: 0 0 auto;
    min-width: 13.4rem;
  }
}
.dropdown-toggle {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 1.2rem;
  padding-right: 0.8rem;
  border-radius: inherit;
  border: 0.1rem solid var(--section-border-bg);
  //box-shadow: 0 0 0 0.1rem var(--section-border-bg);
  background-color: var(--main-element-bg);
  cursor: pointer;
  transition:
          box-shadow 240ms,
          background-color 240ms;

  &.sm {
    height: 3.6rem;

    .dropdown-label {
      transform: translate3d(-0.5rem, -2rem, 0) scale(0.8);
    }
  }

  &.md {
    height: 4rem;
  }

  &.lg {
    height: 4.8rem;

    .dropdown-label {
      transform: translate3d(-0.5rem, -2.6rem, 0) scale(0.8);
    }
  }

  &:active {
    border: 0.1rem solid rgba(var(--blue-color));
    //box-shadow: 0 0 0 0.1rem rgba(var(--blue-color),1);
  }

  &.selected {
    border: 0.1rem solid rgba(var(--blue-color));
    //box-shadow: 0 0 0 0.1rem rgba(var(--blue-color),1);
  }

  &.readonly {
    background-color: var(--input-state-bg);
    border: 0.1rem solid rgba(var(--board-state-bg), 0.15);
    .dropdown-icon {
      display: none;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: none;
  }

  &.error {
    box-shadow: 0 0 0 0.1rem rgba(var(--red-color), 1);
  }
}
.dropdown-txt {
  flex: 1;
  min-width: 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--main-text-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dropdown-icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.2rem;
  color: rgba(var(--main-element-txt-color), 1);
}
.dropdown-menu {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0.6rem;
  border: 0.1rem solid var(--section-border-bg);
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 0.4rem var(--shadow-color);
  background-color: #ffffff;
  animation: fadeIn 240ms;
  z-index: 100;

  &.to-top {
    bottom: 100%;
    margin-bottom: 0.4rem;
  }

  &.to-bottom {
    top: 100%;
    margin-top: 0.4rem;
  }
}
.dropdown-menu-inside {
  max-height: 25.4rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.dropdown-menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  padding: 0.3rem;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: background-color 240ms;

  &:hover {
    background-color: rgba(var(--blue-color), 0.08);

    .dropdown-menu-txt {
      color: var(--blue-secondary-color);
    }
  }

  i {
    font-size: 2.2rem;
    color: rgba(var(--blue-color), 1);
  }
}
.dropdown-menu-txt {
  padding: 0 0.9rem;
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgb(var(--main-element-txt-color));
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: color 240ms;
}
.dropdown-error-message {
  position: absolute;
  top: 100%;
  left: 1.2rem;
  display: block;
  width: 100%;
  margin-top: 0.2rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-transform: capitalize;
  color: rgba(var(--red-color), 1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
}
.dropdown-label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1rem;
  z-index: 2;
  height: 1.7rem;
  margin: auto;
  padding: 0 0.3rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
  background-color: var(--main-element-bg);
  cursor: text;
  color: rgba(var(--main-element-txt-color), 1);
  transition: transform 240ms;
}
.divider {
  width: 0.1rem;
  height: 2.4rem;
  margin: 0 1.6rem;
  background-color: var(--section-border-bg);
}
.nested-dropdown-title {
  font-size: 1.6rem;
  line-height: 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.right-arrow {
  margin-left: auto;
}


.dropdown-content-wr {
  width: 100%;
  padding: 0.6rem;
  position: absolute;
  left: 0;
  right: 0;
  border: 0.1rem solid var(--section-border-bg);
  border-radius: 0.8rem;
  box-shadow:
          0 0 0.3rem 0 var(--shadow-color),
          0 0.4rem 0.4rem 0 var(--shadow-color);
  background-color: var(--main-element-bg);
  animation: fadeIn 240ms;
  z-index: 102;

  &.to-top {
    bottom: 100%;
    margin-bottom: 1rem;
  }

  &.to-bottom {
    top: 100%;
    margin-top: 0.4rem;
  }
}
.search-panel {
  position: relative;
  z-index: 101;
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  border-radius: inherit;
  box-shadow: 0 0 0 0.1rem var(--section-border-bg);
  background-color: var(--main-element-bg);
}
.search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.8rem;
  height: 3.8rem;
  font-size: 1.7rem;
  color: rgba(var(--main-element-txt-color), 1);
  cursor: pointer;
}
.search-input {
  width: 100%;
  height: 100%;
  padding: 0 1.2rem;
  box-sizing: border-box;
  border: none;
  border-radius: 0.8rem;
  outline: none;
  font-size: 1.4rem;
  line-height: 1.8rem;
  background-color: inherit;
  color: rgba(var(--main-element-txt-color), 1);

  &::placeholder {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--main-text-color);
  }
}
.menu-header {
  display: flex;
  align-items: center;
  width: 100%;
}
.menu-header-action {
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
  padding: 0 1rem;
  cursor: pointer;
  box-shadow: inset 0 -0.1rem 0 0 var(--section-border-bg);

  i {
    font-size: 1.8rem;
  }
}
.menu-header-action-heading {
  padding: 0 1.2rem;
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgb(var(--main-element-txt-color));
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.menu-contents {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .menu-option {
    &.selected {
      background-color: rgba(var(--blue-primary-color), 0.08);
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
.menu-options {
  max-height: 25.6rem;
}
.menu-option {
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  margin-top: 0.4rem;
  padding: 0 1.2rem;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: background-color 240ms;
  &.title-option {
    background-color: rgba(var(--blue-color),0.08);
    color: rgba(var(--blue-color));
    gap: 0.2rem;
    .dropdown-icon {
      color: rgba(var(--blue-color));
    }
  }
  &.default-option {
    &:hover {
      background-color: var(--row-bg-color);
    }

    &:active {
      background-color: rgba(var(--blue-color), 0.08);
    }

    &.active {
      background-color: rgba(var(--blue-color), 0.08);

      .menu-icon,
      .checkbox-label {
        color: rgba(var(--blue-color), 1);
      }
    }

    color: rgba(var(--main-element-txt-color), 1);
  }

  &.danger-option {
    &:hover {
      background-color: rgba(var(--red-color), 0.04);
    }

    &:active {
      background-color: rgba(var(--red-color), 0.08);
    }

    color: rgba(var(--red-color), 1);
  }

  &.disabled-option {
    opacity: 0.5;
    pointer-events: none;
  }
}
.menu-content {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  .checkbox-holder {
    gap: 0;
    margin-bottom: 0;
  }
}
.menu-checkbox {
  position: relative;
  width: 1.8rem;
  height: 1.8rem;

  &:hover {
    .checkbox-checkmark {
      color: rgba(var(--blue-color), 1);
    }

    input[type="checkbox"]:checked ~ .checkbox-checkmark {
      background-color: var(--blue-secondary-color);
    }
  }

  input[type="checkbox"] {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }

  input[type="checkbox"]:checked ~ .checkbox-checkmark {
    background-color: rgba(var(--blue-color), 1);
    border-color: transparent;
  }

  input[type="checkbox"]:checked ~ .checkbox-checkmark:after {
    opacity: 1;
  }
}
.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0.2rem;
  border-style: solid;
  border-color: var(--section-border-bg);
  border-radius: 0.3rem;
  pointer-events: none;
  transition:
          border-color 240ms,
          background-color 240ms;

  &.check {
    &:after {
      top: 0.1rem;
      left: 0.5rem;
      border-width: 0 0.2rem 0.2rem 0;
      transform: rotate(45deg);
      width: 0.5rem;
      height: 1rem;
      border-style: solid;
      border-color: #ffffff;
    }
  }
  &.minus {
    &:after {
      top: 0.1rem;
      left: 0.2rem;
      bottom: 0;
      width: 1rem;
      height: 0.2rem;
      margin: auto;
      border-radius: 0.3rem;
      background-color: #ffffff;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;
    transition: opacity 240ms;
  }
}
.checkbox-label {
  display: block;
  font-size: 1.6rem;
  line-height: 2rem;
  transition: color 240ms;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menu-icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  transition: color 240ms;

  .icon-settings {
    font-size: 2rem;
  }
}
.checkbox-row {
  display: flex;
  align-items: center;

  .checkbox-label {
    margin-left: 0.5rem;
  }
}
.menu {
  .checkbox-search {
    padding-bottom: 0.8rem;
    margin-bottom: 0.5rem;
    border-bottom: 0.1rem solid var(--section-border-bg);
  }
}
