.icon-wr {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;

  &.with-bg {
    border-radius: 0.8rem;
  }

  &.xxs {
    i {
      font-size: 0.8rem;
    }
    &.with-bg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  &.xs {
    i {
      font-size: 1.2rem;
    }
    &.with-bg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  &.sm {
    i {
      font-size: 1.8rem;
    }
    &.with-bg {
      width: 3.8rem;
      height: 3.8rem;
    }
  }
  &.md {
    i {
      font-size: 2.4rem;
    }
    &.with-bg {
      width: 4.8rem;
      height: 4.8rem;
    }
  }
  &.lg {
    i {
      font-size: 3.2rem;
    }
    &.with-bg {
      width: 6.4rem;
      height: 6.4rem;
    }
  }
}
