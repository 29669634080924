.datepicker {
  display: inline-grid;
  grid-template-columns: 12fr;
  grid-template-rows: auto 11fr;
  padding: 1.6rem;
  border: 0.1rem solid var(--section-border-bg);
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 0.4rem var(--shadow-color);
  background-color: var(--main-element-bg);
}
.date-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.date-actions {
  display: flex;
}
.date-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--main-element-bg);
  &:hover {
    background-color: var(--row-bg-color);
  }

  &:not(:last-child) {
    margin-right: 0.4rem;
  }

  i {
    font-size: 1.2rem;
  }
}
.date-month-year {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgb(var(--main-element-txt-color));
}
.calendar-days {
  width: 100%;
}
.calendar-days-row {
  display: flex;

  &.days-heading {
    .calendar-day {
      font-weight: bold;
    }
  }
}
.calendar-days-content {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;

  .calendar-days-col {
    cursor: pointer;
    transition: background-color 240ms;

    &:hover {
      background-color: var(--row-bg-color);
    }

    &.selected {
      background-color: rgba(var(--blue-color), 1);

      .calendar-day {
        color: rgba(var(--white-text-color), 1);
      }
    }

    &.disabled {
      pointer-events: none;

      .calendar-day {
        color: rgba(var(--main-element-txt-color), 0.5);
      }
    }
  }
}
.calendar-days-col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
}
.calendar-day {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgb(var(--main-element-txt-color));
  transition: color 240ms;
}
