.popover-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  padding: 0.3rem 1.2rem;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: background-color 240ms;

  &:hover {
    background-color: rgba(var(--blue-color), 0.08);

    > .popover-item-content {
      color: var(--blue-secondary-color);
    }

    > .popover-icon {
      color: var(--blue-secondary-color);
    }
  }

  &.sign-out {
    .popover-item-content,
    .popover-icon {
      color: rgba(var(--red-color), 1);
    }
  }
}
.popover-item-content {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  transition: color 240ms;
  .icon-image-wr {
    width: 2rem;
  }
  i {
    font-size: 2.2rem;
  }
}
.popover-icon {
  flex: 0 0 auto;
  font-size: 1.2rem;
  transition: color 240ms;
}
.popover-txt {
  padding: 0 0.9rem;
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  &:only-child {
    padding: 0;
  }
}

.nested-popover-content {
  max-height: 14.4rem;
  margin-top: 0.6rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.nested-popover-txt {
  padding: 0 0.9rem;
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--main-element-txt-color), 1);
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: color 240ms;
}

.nested-popover-heading {
  width: 100%;
  display: flex;
  align-items: center;
  height: 4.8rem;
  padding: 0.3rem 1.2rem;
  cursor: pointer;
  box-shadow: 0 0.1rem 0 0 rgba(var(--section-border-bg), 1);
}

.nested-popover-heading-content {
  display: flex;
}

.popover-image {
  width: 2rem;
}
