.input-holder {
  position: relative;
  width: 100%;
}
.input-item {
  width: 100%;
  position: relative;
  border: 0.1rem solid var(--section-border-bg);
  border-radius: 0.8rem;
  &.error {
      border-color: rgba(var(--red-color),1);

      &:focus {
        border-color: rgba(var(--red-color),1);
      }
  }
  &:focus-within {
    border-color: rgba(var(--blue-color),1);
  }
}

.dark {
  .input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #1D2125 inset !important;
      border: 0.1rem solid rgba(0, 127, 219, 1);
      -webkit-text-fill-color: #ffffff;
    }
  }
}

.light {
  .input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #FFFFFF inset !important;
      border: 0.1rem solid rgba(0, 127, 219, 1);
      -webkit-text-fill-color: #000000;
    }
  }
}

.input {
  position: relative;
  z-index: 2;
  display: block;
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 4.8rem;
  padding: 0 1.2rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  border-radius: 0.8rem;
  background-color: var(--main-element-bg);
  transition: border-color 240ms;
  color: rgb(var(--main-element-txt-color));

  &:not(:placeholder-shown) + .input-label {
    transform: translate3d(-0.5rem, -2.4rem, 0) scale(0.8);
    cursor: default;
    z-index: 10;
  }

  &:focus + .input-label {
    transform: translate3d(-0.5rem, -2.4rem, 0) scale(0.8);
    cursor: default;
    z-index: 10;
    background-color: var(--main-element-bg);
    .label-txt {
      color: rgb(var(--main-element-txt-color));
    }
  }

  &[readonly] {
    background-color: var(--input-state-bg);
    border-color: rgba(var(--board-state-bg),0.15);
    &:not(:focus) {
      & + label {
        background-color: var(--input-state-bg);
        .label-txt {
          color: rgb(var(--main-element-txt-color));
        }
      }

      & + label:after {
        background-color: var(--input-state-bg);
      }
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    & + label {
      pointer-events: none;

      .label-txt {
        opacity: 0.5;
      }
    }
  }
}
.input-label {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1rem;
  z-index: 1;
  height: 1.7rem;
  margin: auto;
  padding: 0 0.3rem;
  cursor: text;
  transform: translate3d(0, 0, 0) scale(1);
  transition: transform 240ms;

  &.required {
    &:after {
      content: "*";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      width: 0.8rem;
      height: 100%;
      margin-left: -0.2rem;
      color: rgba(var(--blue-color),1);
      background-color: var(--main-element-bg);
    }
  }
}
.label-txt {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
  //color: var(--form-elemnt-bg);
  color:transparent;
}
.input-error-message {
  position: absolute;
  top: 100%;
  left: 1.2rem;
  display: block;
  width: 100%;
  margin-top: 0.2rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-transform: capitalize;
  color:  rgba(var(--red-color),1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
}
.input-actions {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1.2rem;

  & + .input-item {
    input {
      padding-right: 7rem;
    }
  }
  &.input-password-action {
    .input-action {
      i {
        font-size: 2rem;
        color: var(--main-text-color);
      }
    }
  }
}
.input-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  i {
    font-size: 2.1rem;
    color: rgba(var(--main-element-txt-color),1);
  }
  &:hover {
    ~ .verified-txt {
       opacity: 1;
    }
  }
}
.waring-icon {
  font-size: 2rem;
  color: rgb(var(--yellow-color));
}
.verified-txt {
  opacity: 0;
  min-width: 13.4rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;
  display: inline-block;
  padding: 0.6rem 1.2rem;
  background-color: var(--main-element-txt-color);
  border-radius: 0.6rem;
  color: rgba(var(--white-text-color),1);
  position: absolute;
  right: -0.5rem;
  top: -1.9rem;
  text-align: center;
  &:before {
    content:'';
    position: absolute;
    bottom: -0.5rem;
    right: 2rem;
    width: 0;
    height: 0;
    border-left: 0.7rem solid transparent;
    border-top: 0.7rem solid var(--main-element-txt-color);
    border-right: 0.7rem solid transparent;
  }
}

// Tooltip  style
.input-tooltip {
  max-width: 235px;
  width: 100%;
  position: absolute;
  top: 4.6rem;
  right: 0.2rem;
  background-color: var(--calendar-element-bg);
  box-shadow: 0.1rem 0.3rem 0.4rem 0 var(--shadow-color), 0 0 0.4rem 0 var(--shadow-color);
  border-radius: 0.6rem;
  z-index: 3;

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-right: 0.9rem solid transparent;
    border-left: 0.9rem solid transparent;
    border-bottom: 0.9rem solid var(--calendar-element-bg);
    position: absolute;
    right: 1.4rem;
    top: -0.9rem;
    filter: drop-shadow(0.2rem 0 0.2rem var(--shadow-color));
  }

}
.input-tooltip-inner {
  padding: 0.8rem 0.8rem 0.8rem 2.4rem;
}
.input-tooltip-row {
  position: relative;
  cursor: pointer;
  &.active {
    .input-tooltip-row-info {
      color: rgba(var(--green-color),1)
    }
    &:after {
      background-color: rgba(var(--green-color),1)
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0.4rem;
    left: -1.6rem;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: rgba(var(--board-state-bg), 0.3);
    transition: background-color 240ms;
  }
  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}
.input-tooltip-row-info {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;
  color: var(--main-text-color);
  transition: color 240ms;
}
textarea {
  display: block;
  box-sizing: border-box;
  resize: none;
  font-family: inherit;
  width: 100%;
  height: 12rem;
  margin: 0;
  padding: 1.5rem 1.2rem;
  border: none;
  border-radius: 0.8rem;
  background-color: var(--main-element-bg);
  transition: border-color 240ms;
  color: rgb(var(--main-element-txt-color));

  &::placeholder {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  &:not(:placeholder-shown) + .input-label {
    transform: translate3d(-0.5rem, -2.4rem, 0) scale(0.8);
    cursor: default;
    z-index: 10;
  }

  &:focus + .input-label {
    transform: translate3d(-0.5rem, -2.4rem, 0) scale(0.8);
    cursor: default;
    z-index: 10;
    background-color: var(--main-element-bg);
    .label-txt {
      color: rgb(var(--main-element-txt-color));
    }
  }
  &:focus {
    border-color: rgba(var(--blue-color),1);;
  }

  &[readonly] {
    background-color: var(--input-state-bg);
    border-color: rgba(var(----board-state-bg),0.15);
    &:not(:focus) {
      & + label {
        //background-color: var(--input-state-bg);
        .label-txt {
          color: transparent;
        }
      }

      & + label:after {
        background-color: var(--input-state-bg);
      }
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    & + label {
      pointer-events: none;

      .label-txt {
        opacity: 0.5;
      }
    }
  }

  & + .input-label {
    top: 1.5rem;
    bottom: unset;
    //.label-txt {
    //  color: rgb(var(--main-element-txt-color),0.6);
    //}
  }
}
/////new password
.new-password-block{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-password-wrp{
  width: 100%;
  max-width: 54.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  //position: absolute;
  //left: 50%;
  //top: 50%;
  //transform: translate(-50%, -50%);

  .reset-password-form {
    width: 100%;
  }

  .gravity-logo{
    max-width: 26rem;
    height: 7.4rem;
    margin-bottom: 2.4rem;
  }
  .sign-in-form-header{
    text-align: center;
  }
}
.new-password-holder {
  width: 100%;
  padding: 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--main-element-bg);
  //border: 0.1rem solid var(--section-border-bg);
  border-radius: 1.2rem;
}
.new-pass-tooltip-block{
  width: 100%;
  margin-bottom: 2.4rem;
}
.new-pass-inp-row{
  width: 100%;
  margin-bottom: 2.1rem;
}

/////new password
