.section {
  width: 100%;
  padding: 1.6rem;
  border-radius: 1.6rem;
  background-color: var(--main-element-bg);
  box-shadow: 0 0 0 0.1rem var(--section-border-bg);

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.report-statistics-wrapper {
  padding: 1.6rem;
  .table-info{
    .table-info, .table-holder{
      overflow: inherit;
      max-height: inherit;
    }
  }

  .table-row-inner {
    &:last-child {
      box-shadow: none;
    }
  }

  .section {
    margin-bottom: 1.6rem;
  }
  > .table {
    .table{
      border-radius: 0;
    }
    > .table-content {
      > .table-holder {
        border-bottom-right-radius: 1.6rem;
        border-bottom-left-radius: 1.6rem;

        & > .table-top > .table-row.header-row:before {
          display: none;
        }

        & > .table-bottom > .table-info > .table-row > .table-nested:before {
          display: none;
        }

        & > .table-bottom > .table-info > .table-row > .table-row-inner:after {
          display: none;
        }
      }
      .table-row{
        &:not(.header-row):last-child{
          box-shadow: none;
          > .table-nested{
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.section-header {
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2rem;
  color: rgba(var(--main-element-txt-color), 1);
  text-transform: capitalize;
  .header-title {
    font-size: 1.6rem;
    font-weight: 500;
  }
  .header-value {
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: 600;
  }
}
