.dashboard-top-block-items{
  display: flex;
  align-items: center;
  position: relative;
}
.partners-text-number-block{
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  padding-left: 1.6rem;
  padding-right: 0.5rem;
}
.partners-text {
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-transform: capitalize;
  color: var(--main-text-color);
}
.partners-number{
  font-size: 2rem;
  line-height: 2.6rem;
  color: rgba(var(--main-element-txt-color),1);
}
.dashboard-block-items-right{
  //position: absolute;
  //top: 50%;
  //right: 3rem;
  //transform: translateY(-50%);
  margin-left: 1.2rem;
  pointer-events: none;
  i{
    font-size: 1.9rem;
    color: var(--main-text-color);
  }
}

.info-item-wrp {
  flex: 0 0 auto;
}
