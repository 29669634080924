$version-control: '?v=#{random(777)}';

@font-face {
  font-family: 'SuisseBPIntl';
  src: url('../fonts/SuisseBPIntl-Medium.woff2#{$version-control}') format('woff2'),
  url('../fonts/SuisseBPIntl-Medium.woff#{$version-control}') format('woff'),
  url('../fonts/SuisseBPIntl-Medium.ttf#{$version-control}') format('truetype'),
  url('../fonts/SuisseBPIntl-Medium.eot?#iefix#{$version-control}') format('embedded-opentype'),
  url('../fonts/SuisseBPIntl-Medium.eot#{$version-control}'),
  url('../fonts/SuisseBPIntl-Medium.svg#{$version-control}') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'SuisseBPIntl';
  src: url('../fonts/SuisseBPIntl-Bold.woff2#{$version-control}') format('woff2'),
  url('../fonts/SuisseBPIntl-Bold.woff#{$version-control}') format('woff'),
  url('../fonts/SuisseBPIntl-Bold.ttf#{$version-control}') format('truetype'),
  url('../fonts/SuisseBPIntl-Bold.eot?#iefix#{$version-control}') format('embedded-opentype'),
  url('../fonts/SuisseBPIntl-Bold.eot#{$version-control}'),
  url('../fonts/SuisseBPIntl-Bold.svg#{$version-control}') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}
