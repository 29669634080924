.toast-message-holder {
  position: absolute;
  z-index: 100;
  width: 40rem;

  &.top-right {
    top: 1.6rem;
    right: 1.6rem;
  }

  &.top-left {
    top: 1.6rem;
    left: 1.6rem;
  }
}
.toast-message {
  width: 100%;
  height: 6.4rem;
  background-color: var(--main-element-bg);
  border-style: solid;
  border-color: var(--section-border-bg);
  border-top-width: 0.1rem;
  border-left-width: 0.1rem;
  border-right-width: 0.1rem;
  box-shadow: 0 0.4rem 0.4rem var(--shadow-color);
  border-radius: 1.2rem;
  overflow: hidden;
  animation: fadeIn 240ms ease-in-out;

  &:not(:last-child) {
    margin-bottom: 0.7rem;
  }
}
.toast-message-inside {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1.2rem;
}
.toast-message-loader {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.3rem;
  border-radius: 0.3rem;
  animation: toast-timer linear;
  @keyframes toast-timer {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  }
}
.toast-message-content {
  display: flex;
  align-items: center;
  width: 100%;
}
.toast-message-info {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.toast-message-actions {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}
.toast-message-action {
  cursor: pointer;

  &.close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    color: var(--main-text-color);
    font-size: 1.2rem;
  }
}
.toast-message-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.toast-message-txt {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: rgb(var(--main-element-txt-color));
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-left: 1rem;
}
