.status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.6rem;
  height: 2.6rem;
  padding: 0 0.6rem;
  border-width: 0.1rem;
  border-style: solid;
  border-radius: 5rem;

  &.state-green {
    background-color: rgba(var(--green-color),0.08);
    border: 0.1rem solid rgba(var(--green-color),0.3);
    .states-text {
      color: rgba(var(--green-color), 1);
    }
  }
  &.state-red {
    background-color: rgba(var(--red-color),0.08);
    border: 0.1rem solid rgba(var(--red-color),0.3);
    .states-text {
      color: rgba(var(--red-color),1);
    }
  }
}

.status-text {
  padding: 0 0.6rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
