.filters {
  width: 100%;
  margin-bottom: 1.6rem;
}

.filters-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 28rem;
  height: 4.8rem;
  padding: 0 2rem;
  border: 0.1rem solid var(--section-border-bg);
  border-radius: 0.8rem;
  background-color: var(--main-element-bg);
  cursor: pointer;
  outline: none;
  color: rgba(var(--main-element-txt-color), 1);
  transition: background-color 240ms;

  i {
    font-size: 1.8rem;
  }

  &:hover {
    background-color: var(--row-bg-color);
  }

  &:active {
    background-color: var(--option-hover-bg);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.filters-btn-txt {
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: capitalize;
  padding: 0 0.8rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.filters-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.4rem;
  height: 2.4rem;
  padding: 0.4rem;
  border-radius: 1.2rem;
  background-color: rgba(var(--blue-color), 1);
  color: #ffffff;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.filters-content {
  grid-template-columns: 11fr auto;
  grid-gap: 3.3rem;
  width: 100%;
  margin-top: 1.6rem;
  display: none;
  animation: slide-down 240ms linear;

  &.open {
    display: grid;
  }
}

.filters-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20.8rem, 1fr));
  grid-gap: 1.6rem;
}

.filters-input {
}

.filters-actions {
  display: grid;
  grid-gap: 1.2rem;
  grid-template-columns: 6fr 6fr;

  .button {
    min-width: 10rem;
  }
}

@keyframes slide-down {
  0% {
    transform: translate3d(0, -2rem, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

//fransaction filter style

.transaction-dropdown {
  max-width: 32rem;
  width: 100%;
}

.nested-dropdown-content-wrapper {
  position: relative;

  &.open-nested {
    .nested-dropdown-content {
      opacity: 1;
      pointer-events: unset;
    }

    .dropdown-main-content-wr {
      opacity: 0;
      pointer-events: none;
    }
  }
}

//.dropdown-content-wr {
//  opacity: 1;
//}
.dropdown-main-content-wr {
  opacity: 1;
  top: 0.4rem;
}

.transaction-menu-content {
  flex: 1 1;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;

  .dropdown-icon {
    pointer-events: none;
  }
}

.transaction-menu-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;

  .menu-option {
    &.selected {
      background-color: rgba(var(--blue-primary-color), 0.08);
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.transaction-checkbox-label {
  flex: 1;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
  transition: color 240ms;
  display: flex;
  align-items: center;
  //TODO need ui change

  &.checkbox {
    position: relative;
    width: 1.8rem;
    height: 1.8rem;
    flex: 0 0 auto;

    &:hover {
      .checkbox-checkmark {
        border-color: rgb(var(--blue-color));
      }

      input[type="checkbox"]:checked ~ .checkbox-checkmark,
      .checkbox-checkmark.minus {
        background-color: var(--blue-secondary-color);
      }
    }

    input[type="checkbox"] {
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }

    input[type="checkbox"]:checked ~ .checkbox-checkmark,
    .checkbox-checkmark.minus {
      background-color: rgb(var(--blue-color));
      border-color: transparent;
    }

    input[type="checkbox"]:checked ~ .checkbox-checkmark:after,
    .checkbox-checkmark.minus:after {
      opacity: 1;
    }
  }
}

.transaction-checkbox-label-number {
  padding: 0.1rem 0.8rem;
  border-radius: 10rem;
  color: rgb(var(--white-text-color));
  background-color: rgb(var(--blue-primary-color));
  display: inline-block;
  margin-left: 0.8rem;
  pointer-events: none;
}

.nested-dropdown-content {
  top: 0.4rem;
  right: 0;
  z-index: 1002;
  padding: 0;
  opacity: 0;
  pointer-events: none;

  .menu-options {
    max-height: 21.4rem;
  }
}

.nested-dropdown-menu-option-title {
  padding: 1.2rem;
  border-bottom: 0.1rem solid var(--section-border-bg);
  color: rgba(var(--main-element-txt-color), 1);

  .icon-wr {
    i {
      font-size: 1.6rem;
    }
  }
}

.menu-option-footer {
  padding: 1.2rem;
  margin-top: 0.8rem;
  border-top: 0.1rem solid var(--section-border-bg);
  display: flex;
  justify-content: flex-end;
}

.reset-btn {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
  color: rgb(var(--blue-primary-color));

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.filter-flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .dropdown {
    min-width: 20.4rem;
    max-width: 20.4rem;
  }

  .dropdown:last-child {
    max-width: 20.4rem;
  }
}

.divider-line {
  width: 100%;
  height: 0.1rem;
  box-shadow: inset 0 -0.1rem 0 0 var(--section-border-bg);
}

.filters-main-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.6rem;

  .dropdown {
    flex: 1;
  }
}

//.checkbox-checkmark {
//  &.check {
//    &:after {
//      opacity: 1;
//    }
//  }
//}
