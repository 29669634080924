.loader-wr {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
  justify-content: center;
  z-index: 1;
  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.loader {
  width: 7.2rem;
  height: 7.2rem;
}
.loader-layers {
  display: block;
  width: 100%;
  height: 100%;
}
.loader-layer {
  transform-origin: center;

  &:nth-child(1) {
    animation: loader-rotate 720ms linear infinite;
    stroke: rgba(var(--main-element-txt-color), 0.3);
  }

  &:nth-child(2) {
    animation: loader-rotate 1.8s linear infinite;
    stroke: rgb(var(--blue-color));
  }

  &:nth-child(3) {
    animation: loader-rotate 480ms linear infinite reverse;
    stroke: rgba(var(--main-element-txt-color), 0.3);
  }
}
