.jackpot-page {
  .settings-inputs {
    [class*="is-mobile"] & {
      grid-template-columns: 1fr;
    }
  }
}
.jackpot-item-row{
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 1.6rem;
  [class*="is-mobile"] & {
    margin-bottom: 1.2rem;
  }
}
.jackpot-item {
  height: 100%;
  padding: 0;
}
.jackpot-item-inner {
  display: block;
  padding: 1.6rem;
  cursor: pointer;
  [class*="is-mobile"] & {
    padding: 1.2rem;
  }
}
.jackpot-item-title {
  color: rgb(var(--main-element-txt-color));
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 500;
  [class*="is-mobile"] & {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}
.jackpot-item-info {
  color: rgba(var(--main-element-txt-color),0.6);
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 500;
  [class*="is-mobile"] & {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

//radio button
.radio-btn {
  margin-left: auto;
}
.radio-checkmark {
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: 0.3rem solid var(--section-border-bg);
  position: relative;
  transition: border-color 240ms;
  [class*="is-mobile"] & {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.radio-input {
  display: none;
  padding: 0;
  opacity: 0;
  &:checked ~ .radio-checkmark {
    border-color: rgba(var(--blue-color),1);
    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 0.3rem;
      left: 0.3rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: rgba(var(--blue-color),1);
      [class*="is-mobile"] & {
        width: 0.9rem;
        height: 0.9rem;
        top: 0.2rem;
        left: 0.2rem;
      }
    }
  }
}
