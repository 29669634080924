.dashboard-wrp {
  width: 100%;
  height: 100%;
  position: relative;
  .user-reports-wrapper {
    padding: 0;
  }
}
.dashboard-top-block {
  width: 100%;
  height: 8rem;
  display: flex;
}

.revenue-block-items {
  width: 100%;
  height: 14.3rem;
  background-color: rgba(var(--blue-color), 0.04);
  border: 0.1rem solid var(--section-border-bg);
  border-radius: 1.2rem;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
}
.total-bet-amount-block {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: 0.1rem solid var(--section-border-bg);
  border-radius: 1.6rem;
  padding: 1.6rem;
  &:not(:first-child) {
    margin-left: 1.6rem;
  }
}
