.grid-container {
  display: grid;
  margin-bottom: 1.6rem;
  .grid-container {
    margin-bottom: 0;
  }
}
.grid-container-col {
  grid-template-columns: 2fr 3fr 3fr !important;
}

