.multi-currency-dropdown-holder {
  position: relative;
  min-width: 10.4rem;
  margin-right: auto;

  &.active {
    .multi-currency-dropdown-content {
      grid-template-rows: 1fr;
    }

    .multi-currency-dropdown-country-wrapper {
      width: 26.8rem;
    }
  }
}

.multi-currency-dropdown-country-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  padding: 0.6rem 0.4rem 0.6rem 1.2rem;
  cursor: pointer;

  &:hover {
    border-radius: 0.8rem;
    background-color: var(--row-bg-color);
  }
}

.multi-currency-dropdown-country-holder {
  flex: 1;
  min-width: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.multi-currency-dropdown-country-header {
  display: block;
  width: 100%;
  max-width: 22.7rem;
  font-size: 1.6rem;
  line-height: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.outer-header {
    color: rgba(var(--main-element-txt-color), 1);
  }
  //
  //&.inner-header {
  //    color: rgba(var(--blue-color), 1);
  //}
}

.multi-currency-dropdown-country-title {
  font-size: 1.2rem;
  line-height: 1.6rem;

  &.outer-title {
    color: rgb(var(--main-element-txt-color), 0.8);
  }

  //&.inner-title {
  //    color: rgba(var(--blue-color), 1);
  //}
}

.multi-currency-dropdown-inner-holder {
  position: absolute;
  top: 5.3rem;
  left: 0;
  //display: flex;
  //flex-direction: column;
  //gap: 0.6rem;
  width: 26.8rem;
  border-radius: 1rem;
  z-index: 100;
  background-color: var(--main-element-bg);
  box-shadow:
    0 0 0.3rem 0 rgba(0, 0, 0, 0.15),
    0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.12);
}

.multi-currency-dropdown-inner-container {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 100%;
  //height: 100%;
  //padding: 0.4rem;
}

.businessOverviewWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 0.6rem 0.6rem 0.6rem 1.6rem;
  border-bottom: 0.1rem solid var(--section-border-bg);
  background-color: var(--main-element-bg);
}

.businessOverviewTxt {
  flex: 1;
  min-width: 0;
  width: 100%;
  display: block;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
  max-width: 11.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(var(--main-element-txt-color), 1);
}

.multi-currency-dropdown-items {
  max-height: 27.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.padding-holder {
  width: 100%;
  padding: 0.4rem;
}

.multi-currency-dropdown-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 240ms;
}

.multi-currency-dropdown-content-inside {
  overflow: hidden;
}

.multi-currency-dropdown-inner-holder-column-1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 0.6rem 1.2rem;
  border-radius: 0.6rem;
  transition: background-color 0.24s;
  cursor: pointer;
  color: rgba(var(--main-element-txt-color), 1);
  &.active,
  &:active {
    background-color: rgba(var(--blue-color), 0.08);
    color: rgba(var(--blue-color), 1);
  }
  &:hover {
    background-color: var(--row-bg-color);
  }
}

.multi-currency-dropdown-inner-holder-column-2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.4rem 0.6rem 0.4rem 0;
  box-shadow: inset 0 0.1rem 0 0 var(--section-border-bg);
}

.multi-currency-dropdown-inner-currency-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}

.multi-currency-dropdown-inner-currency-txt {
  font-size: 1.2rem;
  line-height: 1.6rem;
  //color: rgba(var(--blue-color), 1);
}

//todo multicurrency-dropdown section end
