.not-found-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.not-found-info {
  color: rgba(var(--main-element-txt-color), 1);
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.8rem;
}
.not-found-info-wrp {
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.not-found-img-block {
  max-width: 13.7rem;
  width: 100%;
  margin: 0.3rem 0;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.back-to-btn {
  max-width: 19.5rem;
  width: 100%;
  outline: none;
  border: none;
  background-color: rgba(var(--blue-color),1);
  min-height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: rgba(var(--white-text-color),1);
  border-radius: 0.8rem;
  cursor: pointer;
  padding: 1rem;
  &:hover {
    background-color: var(--blue-secondary-color);
  }
}
.back-to-btn-txt {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
